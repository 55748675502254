:root {
  --blue: #009fe3;
  --magenta: #e6007e;
  --lightpink: #fcd2e9;
  --lightblue: #32b2e8;
  --darkgray: #666666;
}

.react-datepicker {
  font-size: 1em;
  font-weight: 400;
  display: flex;
}
.react-datepicker__input-container input {
  background: transparent;
  border-bottom: 1px solid var(--magenta);
  border-radius: 0;
  font-size: 1em;
  font-weight: 400;
  width: 100%;
  margin-left: 0;
  text-align: center;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  border: 0.45em solid transparent;
}

.react-datepicker__day--selected {
  background-color: var(--magenta);
}

.react-datepicker__time-list-item--selected {
  background-color: var(--magenta);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--magenta);
  color: white;
}

react-datepicker__day--in-range {
  background-color: var(--lightpink) !important;
  color: white;
}

.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover {
  background-color: var(--lightpink);
  color: white;
}

.mdatepicker {
  width: 100%;
}

.mdatepicker-wrapper {
  display: flex;
  /* justify-content: space-around; */
  justify-content: center;
  margin: 0 0 1em 0;
  gap:3px;
}

.mdatepicker-wrapper1 {
  display: flex;
  /* justify-content: space-around; */
  justify-content: center;
  margin: 0.5em 0;
  gap: 3px;
}

.mdatepicker-wrapper>div {
  align-self: flex-end;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  display: block;
}

.react-datepicker__time-container {
  width: 100px;

}

.react-datepicker__time-box {
  width: 100% !important;
}

.first {
  margin: 0;
}


.dpText {
  font-size: 13px;
  padding-top: 5px;
}


.datePicker2Container {
  background-color: #32b2e84d;
  border-radius: 11px;
  padding: 10px;
}

.mdatepicker-wrapper1 input {
  background-color: #c2edff4d;

}

.mdatepicker-wrapper input {
  background-color: #c2edff4d;

}

.dpExtraText {
  display: flex;
  gap: 3px;
}

.dpExtraText p {
  font-size: 11px;
}
