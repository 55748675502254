:root {
  --blue: #009fe3;
  --magenta: #e6007e;
  --lightpink: #fcd2e9;
  --lightblue: #42c0f6;
  --babyblue: #bee6f7;
  --darkblue: #0c80b2;
  --darkgray: #666666;
  --black: #0d1117;
  --white: #fefefe;
  --gradient: linear-gradient(
    29deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 159, 227, 1) 56%,
    rgba(255, 255, 255, 1) 100%
  );
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/Opensans/OpenSans-Bold.woff2") format("woff2"),
    url("./fonts/Opensans/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/Opensans/OpenSans-Light.woff2") format("woff2"),
    url("./fonts/Opensans/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/Opensans/OpenSans-Medium.woff2") format("woff2"),
    url("./fonts/Opensans/OpenSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/Opensans/OpenSans-Regular.woff2") format("woff2"),
    url("./fonts/Opensans/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body { 
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans";
  font-weight: 300;
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  background-color: var(--blue);
  overflow: auto;
}

button {
  padding: 0;
}

h1 {
  font-size: 3em;
  line-height: 1.1;
  font-weight: 400;
  margin: 0;
}

h2 {
  font-weight: 300;
  font-size: 2em;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 9%;
}

h3 {
  font-weight: 700;
  font-size: 2em;
  line-height: 1.1;
  margin: 0;
}

h4 {
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.4;
  margin: 0;
}

p {
  margin: 0;
  font-size: 1em;
}

.App {
  position: relative;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 3.5rem;
}

.bold {
  font-weight: bold;
}

.rose {
  color: var(--magenta);
}

.flexCol{
  display: flex;
  flex-direction: column;
  /* padding-left: 3%; */
}

.stagesLink {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: var(--blue);
  outline: none;
  font-family: "Open Sans";
  font-weight: 700;
  transition: all 0.2s ease;
}

.stagesLink:hover {
  color: var(--magenta);
}

.content_margin_sides {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto -2rem;
  padding-inline: 2rem;
}

.content_margin_sides_float {
  /* margin-left: 10rem; */
  /* margin-right: 10rem; */
  margin: 4rem 0;
}

.section_1_blue {
  padding-top: 3rem;
  /* padding-bottom: 7rem; */
}

.section_stage_sizes {
  padding-bottom: 3rem;
  position: relative;
}

.full-width {
  width: 100%;
}


.floating_section_booking {
  /* flex-grow: 1; */
  position: relative;
  /* z-index: 99; */
  /* min-width: 1467px; */
  color: var(--white);
  /* background-color: var(--magenta); */
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1272px;
  background: var(--magenta);
  padding-left: 0.75rem;
  border-radius: 3rem;
  box-shadow: 0px -1px 16px 9px rgba(0, 0, 0, 0.3);
  /* box-shadow: inset 0px -1px 24px 8px rgba(92, 3, 53, 1); */
  position: sticky;
    top: 70px;
    z-index: 100;
    margin: 0 auto;
}

.formTextHolder {
  font-size: 25px;
}

.formText {
  font-weight: 300;
  padding-bottom: 1.5rem;
}

.booking_dropdown {
  color: var(--magenta);
  width: 25%;
  display: flex;
  justify-content: flex-start;
  border-radius: 0;
  margin: 0.75rem 2px 0.75rem 0;

  /* position: relative; */
}

.booking_dropdown > button {
  width: 100%;
  height: 3rem;
  background-color: var(--white);
  color: var(--magenta);
  display: flex;
  justify-content: flex-start;
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 0;
}

.booking_dropdown:first-child > button {
  border-radius: 3rem 0 0 3rem;
}

.booking_dropdown:hover .booking_dropdown_text {
  color: var(--black);
}

.booking_dropdown > button.pink {
  background-color: rgba(92, 3, 53, 1);
  color: var(--white);
  width: 20%;
  flex-grow: 1;
  /* border-radius: 0 24px 24px 0; */
}

.lastButton {
  margin: 0;
  padding-left: 0.75rem;
}

.lastButton > button {
  height: 4.5rem;
  border-radius: 0 3rem 3rem 0;
}

/* .booking_dropdown > button.pink:hover{
  background-color: var(--darkgray);
} */

.booking_dropdown_text {
  margin: auto auto auto 0; /* margin: 18px 0 0 69px; */
  font-size: 2em;
  font-weight: 400;
}

.booking_dropdown_text_anfragen {
  margin: auto;
  font-size: 2em;
  font-weight: 400;
}

.booking_dropdown .image {
  width: 10%;
  height: auto;
  margin: auto 0.75rem auto 1rem;
}

.iconImage {
  width: 1.25rem;
  margin: 0.375rem 0.5rem;
}

#dropdown-1 {
  width: 24%;

}
#dropdown-2 {
  width: 24%;
}
#dropdown-3 {
  width: 50%;
}

/* .image{
  width: 12%;
  margin: 10px;
} */

.hidden_dropdown {
  background-color: var(--white);
  position: absolute;
  /* left: -3px; */
  z-index: 2;
  top: 100%;
  color: var(--black);
  width: 25%;
  /* min-width: 20%; */
  padding: 1.5rem;
  margin-left: -0.75rem;  
  border-radius: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1), 0 6px 10px 0 rgba(0,0,0,0.2);
}

.hidden_dropdown::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-bottom: 0.75rem solid var(--white);
  position: absolute;
  top: -0.75rem;
  left: 2rem;
  z-index: 10;
}

#dropdown-3.hidden_dropdown::before {
  border-bottom: 0.75rem solid var(--babyblue);
  left: auto;
  right: 22%;
}

.hidden_dropdownDp {
  border: 3px solid rgb(118, 14, 73);
  background-color: white;
  position: absolute;
  /* left: -3px; */
  z-index: 2;
  top: 100%;
  color: black;
  width: 25%;
  /* min-width: 20%; */
  padding: 20px 30px;  border-radius: 50px;
  box-shadow: 0px -1px 16px 9px rgba(0, 0, 0, 0.3);
}

.hidden_dropdown.left {
  right: -3px;
  left: auto;
}
.hidden_dropdownDp.left {
  right: -3px;
  left: auto;
}

.hidden_dropdown h3 {
  font-size: 1.25em;
  padding-bottom: 0.25rem;
  padding-top: 0;
  padding-left: 0;
  font-weight: 300;
}
.hidden_dropdownDp h3 {
  font-size: 1.2em;
  padding-bottom: 17px;
  padding-top: 0;
  padding-left: 0;
  font-weight: 300;
}

.hidden_dropdown .option {
  display: flex;
}
.hidden_dropdownDp .option {
  display: flex;
}

.hidden_dropdown .option.selected {
  background-color: var(--lightpink);
}
.hidden_dropdownDp .option.selected {
  background-color: var(--lightpink);
}

.hidden_dropdown button.option {
  color: var(--black);
  cursor: pointer;
  width: 100%;
  background-color: var(--white);
  border: none;
}
.hidden_dropdownDp button.option {
  color: black;
  cursor: pointer;
  width: 100%;
  background-color: white;
  border: none;
}

.hidden_dropdown label.option {
  color: var(--black);
  cursor: pointer;
  width: 100%;
  background-color: var(--white);
  border: none;
  border-radius: 0.5rem;
  border: 1px solid var(--black);
}

.hidden_dropdown label.option .standard_text {
  font-size: 1em;
  font-weight: 400;
  margin: auto 0.5rem;
}
.hidden_dropdownDp label.option {
  color: black;
  cursor: pointer;
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 15px;
  border: 1px solid black;
}

button.option > * {
  pointer-events: none;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.hidden_dropdown .standard_text {
  display: inline-block;
  height: 100%;
  margin: auto;
  font-size: 0.875em;
  font-weight: 300;
}

.option .standard_text{
  text-align: center;
}

.divider_underline .standard_text{
  text-align: left;
}

.divider_underline_last .standard_text{
  text-align: left;
}

.anfrageRight .divider_underline .standard_text{
width: 45%;
}

.anfrageLeft .divider_underline .standard_text{
  width: 45%;
  }


.hidden_dropdown label.standard_text {
  margin-left: 0;
  margin-right: 0;
  padding-top: 3%;
}

.hidden_dropdownDp label.standard_text {
  margin-left: 0;
  margin-right: 0;
  padding-top: 3%;
}

input {
  border: none;
  /* margin-left: 1rem; */
  width: 100%;
  text-align: left;
  font-size: 1em;
  font-weight: 300;
}

input:focus {
  background-color: var(--lightpink);
  outline: none;
}

.hidden_dropdown .anfragebtn {
  background-color: var(--magenta);
  color: var(--white);
  width: 100%;
  padding: 1rem;
  border: none;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
  border-radius: 0.75rem;
  cursor: pointer;
}

.hidden_dropdown .anfragebtn:hover {
  background-color: var(--blue);
}
.hidden_dropdownDp .anfragebtn {
  background-color: var(--magenta);
  color: white;
  width: 100%;
  padding: 1rem;
  border: none;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.1rem;
  border-radius: 50px;
}

.hidden_dropdownDP .anfragebtn:hover {
  background-color: var(--blue);
}

.anfragebtn_last {
  background-color: var(--magenta);
  color: var(--white);
  width: 100%;
  padding: 1rem;
  border: none;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
  border-radius: 50px;
}

.anfragebtn_last:hover {
  background-color: var(--blue);
}

.anfragebtn1{
  background-color: var(--magenta);
  color: var(--white);
  width: 100%;
  padding: 1rem;
  border: none;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
  border-radius: 0.75rem;
  cursor: pointer;
}

.anfragebtn1:hover {
  background-color: var(--blue);
}



.text_small {
  font-size: 1em;
  font-weight: 300;
}

.hidden_dropdown .text_small {
  font-size: 1em;
  font-weight: 300;
  margin: auto;
}
.hidden_dropdownDp .text_small {
  font-size: 1em;
  font-weight: 300;
  margin: auto;
}

.divider_underline {
  border-bottom: 1px solid var(--darkgray) !important;
  margin-bottom: 0.25rem;
}

.divider_underline_last {
  border-bottom: 1px solid var(--darkgray) !important;
  margin-bottom: 20px;
}

.anfrageAuswahl {
  display: flex;
  font-size: 0.875rem;
  background-color: var(--babyblue);
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 1rem;
  margin: -1.5rem;
  margin-bottom: 1rem;
}

.anfrageAuswahl b {
  font-size: 1rem;
}

.auswahlTabelle{
  width: 100%;
}

.auswahlTabelle h3 {
  display: flex;
  padding: 0.5rem;
}

.textArea{
  width: 100%;
  max-width: 100%;
  height: 4rem;
  max-height: 300px;

  text-align: left;
  padding: 10px 10px;
  margin-bottom: 1rem;
 
}

.comment{
  padding-top: 22px;
}

.infoAuswahl {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0;
  gap: 0.5rem;
}

.infoAuswahl p {
  font-weight: 500;
}

.infoSize {
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
}

.infoSize > img {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0.25rem 1rem 0.25rem 0;
}

.infoSize b {
  font-size: 1rem;
}

.infoOrt {
  flex: 1 0 auto;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
}

.infoOrt > img {
  height: 3rem;
  width: 3rem;
  margin: 0 0.5rem 0 0;
}

.infoZeit {
  display: grid;
  grid-template-columns: 3rem 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 0;
  align-items: center;
  margin-bottom: 0;
  margin-inline: 0.25rem;
  text-align: center;
}

.infoZeit > img {
  width: 3rem;
  height: 3rem;
}

.infoEventStart,
.infoEventEnde,
.infoAufbau,
.infoAbbau {
  margin: 0 1.125rem;
}

.infoAufbau,
.infoAbbau {
  color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.infoAufbau::after {
  content: '';
  position: absolute;
  background: repeating-linear-gradient(90deg, var(--lightblue), var(--lightblue) 4px, transparent 4px, transparent 8px);
  width: 1.75rem;
  height: 2px;
  top: 50%;
  right: -2rem;
}

.infoAbbau::before {
  content: '';
  position: absolute;
  background: repeating-linear-gradient(90deg, var(--lightblue), var(--lightblue) 4px, transparent 4px, transparent 8px);
  width: 1.75rem;
  height: 2px;
  top: 50%;
  left: -2rem;
}

.infoEventStart,
.infoEventEnde {
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.infoEventStart::after {
  content: '';
  position: absolute;
  background: var(--blue);
  width: 1.75rem;
  height: 2px;
  top: 50%;
  right: -2rem;
}

.timeLabel{
  font-size: 12px;
  align-self: center;
}

.line{
  display: flex;
  justify-content: space-between;

}

.blue_bg {
  background-color: var(--blue);
  color: var(--white);
  display: flex;
}

.magenta_bg {
  background-color: var(--magenta);
  color: var(--white);
}

.black_bg {
  background-color: var(--black);
  color: var(--white);
  /* height: 380px; */
  /* min-height: 100vh; */
}

.white_bg {
  background-color: var(--white);
  color: var(--black);
}

.stage_size_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  gap: 2rem;
  position: sticky;
}

.squareInfo {
  margin: 1.75rem 2rem;
}

.square {
  border-radius: 1rem;
  display: inline-block;
  /* padding: 3rem 3rem 0 3rem; */
  width: 13rem;
  text-align: left;
  position: relative;
  /* cursor: pointer; */
  pointer-events: none;
  background-color: var(--darkblue);
  z-index: 98;
  transition: all 0.7s ease;
}

.h3Size{
  font-size: 1.25rem;
  font-weight: 700;
}

.square:hover {
  background-color: var(--magenta);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1), 0 6px 10px 0 rgba(0,0,0,0.2);
  transform: translateY(-0.5rem);
  transition: all 0.2s ease;
}

.coming_soon {
  background-color: var(--magenta);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1.5em;
}

.square-img {
  width: 40%;
}

.band {
  /* width: 36%;
  position: relative;
  right: -57%;
  bottom: 377px; */

  width: 35%;
  position: fixed;
  right: 4%;
  bottom: -5%;
}

.band_floor {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: var(--white);
  height: 9%;
}

.gradient_bg {
  background: linear-gradient(to bottom, var(--black) 0%, var(--black) 50%, transparent 50%, transparent 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 4rem;
  z-index: 100;
}

.hidden {
  display: none;
}

label.required:before {
  content: "* ";
  color: red;
}

.form-check {
  display: flex;
  padding-top: 1rem;
}

.form-check > input {
  /* width: auto; */
}

.alert {
  color: red;
  transition: opacity 0.6s;
  margin-bottom: 15px;
}

.buehnenpicker-wrapper input {
  display: none;
}

img.formlabelsvg {
  /* width: 50%; */
  width: 15%;
  margin: 0;
}

/* konstantin css */

.selected {
  background-color: #abcdef;
}

#hoverSize:hover {
  background-color: var(--lightblue);
}

.sizeContainer {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}

/* Стилизация checkbox */
.inputCheck {
  width: 20px; /* Размер кастомного checkbox */
  height: 20px;
  background-color: #eee; /* Цвет фона */
  border: 1px solid #ccc; /* Обводка */
  border-radius: 4px; /* Закругление углов */
  display: inline-block;
  cursor: pointer;
  position: relative;
}

/* При выборе меняем цвет фона и ставим галочку */
.inputCheck:checked {
  background-color: var(--lightblue); /* Цвет фона при выборе */
  border-color: var(--blue); /* Цвет обводки при выборе */
}

/* Скрытие оригинального checkbox */
.inputCheck {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Стилизация галочки */
.inputCheck::before {
  content: "\2713"; /* Символ галочки */
  font-size: 20px; /* Размер галочки */
  color: #ffffff; /* Цвет галочки */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

/* Отображаем галочку при выборе */
.inputCheck:checked::before {
  display: inline-block;
}

.CheckBoxPar {
  font-weight: bold;
  padding: 10px;

  color: var(--magenta);
  border-radius: 50px;
}

.checkBoxCont {
  justify-content: start;
  display: flex;
  align-items: center;
}

.checkBoxInfo {
  padding-right: 1rem;
  color: var(--magenta);
}

/* .firstButton {
  border-radius: 25px 0 0 25px;
} */
/* .lastButton {
  border-radius: 0 27px 27px 0;
} */

.check {
  display: flex;
}

#showSecondDatePickerCheckbox {
  width: 25px;
}

button.pink[disabled] {
  background-color: rgba(92, 3, 53, 0.75);
  color: var(--magenta);
  cursor: not-allowed;
  pointer-events: none;
  /* border-radius: 0 25px 25px 0; */
}

.EarseBtn {
  border-radius: 8px;
  padding: 10px;
  background-color: var(--lightblue);
  color: var(--white);
}

.btnContainer {
  display: flex;
  justify-content: center;
}

.anfrageLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.anfrageLeft h3{
  display: flex;
}



.anfrageRight {
  width: 50%;
}

.anfragen {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

#kontakt,
#impressum {
  scroll-margin-top: 5rem;
  color: var(--white);
  background-color: var(--lightblue);
  padding-block: 7rem;
}

#kontakt h4 {
  margin-bottom: 1.5rem;
}

#kontakt a {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--white);
  background-color: var(--darkblue);
  border-radius: 99rem;
  display: inline-flex;
  gap: 0.75rem;
  align-items: center;
  width: auto;
  margin: 1rem 0 0 -1rem;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  transition: all 0.2s ease;
}

#kontakt a:hover {
  background-color: var(--magenta);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-0.25rem);
}

#kontakt p,
#impressum p {
  font-size: 1.25rem;
  margin-block: 2rem;
}

footer {
  color: var(--babyblue);
  background-color: var(--darkblue);
  padding: 1.125rem 0;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  box-sizing: border-box;
  z-index: 98;
}

.copyright {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: 2rem;
}

p {
  margin: 0;
}

.header-buffer {
  background-color: var(--black);
  padding-top: 2rem;
}

header {
  background-color: var(--black);
  color: var(--white);
  padding: 0.75rem 1rem;
  text-align: center;

  position: sticky;
  top: 0px;
  z-index: 100;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  width: 100%;
  max-width: 1280px;
  padding-inline: 2rem;
  margin: 0 auto;
}

.hamburger {
  display: none;
}

.logo {
  width: 17%;
  /* margin-left: 7%; */
}

ul {
  list-style: none;
  display: flex;
}

li {
  margin: 0 10px;
}

a {
  text-decoration: none;
  color: var(--blue);
}

/* Modal */

/* Добавьте следующие стили в ваш CSS-файл */

.stageImg {
  transition: transform 0.3s ease; /* Плавная анимация при увеличении */
  cursor: pointer;
  object-fit: contain;
}

.stageImg.zoomed {
  transform: scale(1.5); /* Увеличьте изображение, как в примере, на 1.5 раза */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999; /* Поместите увеличенное изображение поверх остального контента */
}

/* Add these styles to your existing CSS file or create a new one */

.gallery .hero {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.gallery .hero.zoomed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.gallery .hero.zoomed img.stageImg {
  width: auto; /* Adjust the size as needed */
  height: 63%;
  max-height: 80vh; /* Adjust the maximum height as needed */
}

.gallery .slick-slide.zoomed {
  transform: scale(1.2); /* Adjust the scale factor as needed */
}
.slick-track {
  height: 650px !important;
}


.galleryMini {
  width: 170px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}

.gallerySlider {
  width: 210px;
}

.galleryCont {
  display: flex;
  align-items: center;
}

.galleryDefault {
  position: relative;
}

.stages {
  scroll-margin-top: 7rem;
  padding: 5rem 0;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
  align-items: center;
}

.modalHeader {
  display: flex;
  /* justify-content: space-around; */
  gap: 40px;
  align-items: center;
  flex-direction: column;
  padding-bottom: 15px;
  /* min-height: 180px; */
}

.modal-overlay {
  position: statick;
  top: -60px;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal {
  width: 100%;
  height: 80%;
  color: var(--black);
  background: var(--white);
  /* background: linear-gradient(
    29deg,
    rgba(28, 28, 28, 1) 26%,
    rgb(0 62 89 / 95%) 46%,
    rgb(26 26 26) 64%
  ); */
  padding: 20px 20px 50px;
    /* border-radius: 8px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 98%;
  overflow-y: auto;
}

.modal::-webkit-scrollbar {
  display: none; /* Скрыть бегунок скролла */
}



.modalStageName {
  /* padding: 0 0 0 3rem; */
  font-size: 60px;
}

.descContainer{
  width: 62%;
  align-self: start;
  padding-left: 35px;
  /* padding-top: 15px; */
}


.modalDesc {
  margin: 0 15px;
  padding: 0 35px 35px;
  /* width: 500px; */
  width: 100%;
}

.hero {
  position: relative;
}

.carousel-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 900px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  /* padding: 0 10px; */
}
.carousel-arrows1 {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  width: 2.5%;
  display: flex;
  justify-content: space-between;
  left: 1264px;
  /* z-index: 99; */
  /* padding: 0 10px; */
}

.arrow-left,
.arrow-right {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 40px;
  cursor: pointer;
  border-radius: 50px;
}

.arrow-left {
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
}

.arrow-right {
  margin-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 10px;
}

.arrow-left:hover,
.arrow-right:hover {
  background-color: rgba(255, 255, 255, 0.8); /* цвет при наведении */
}

/* Добавьте следующий CSS в ваш файл стилей */

/* Вертикальные стрелки для слайдера */
.slick-prev,
.slick-next {
  top: 50%; /* Размещение стрелок по центру по вертикали */
  transform: translateY(-50%);
}

.slick-prev {
  left: -20px; /* Подстройте положение стрелки влево */
  transform: translateY(-50%) rotate(+90deg);
  position: absolute;
  left: 89px;
  top: 16px;
}

.slick-next {
  right: -20px; /* Подстройте положение стрелки вправо */
  transform: translateY(-50%) rotate(90deg);
  position: absolute;
  left: 89px;
  top: 685px;
}

.slick-slider {
  padding: 40px 16px;
  height: 700px;
}

.slick-vertical .slick-slide {
  width: 50%;
  height: 19%;
}

.stageImg {
  width: 900px;
  height: 700px;
  border-radius: 15px;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1.5rem;
  /* min-width: 65%; */
  /* padding: 0 0 0 2rem; */
  padding: 20px 0 20px 2rem;
  align-items: center;
}

.infoContainer {
  padding-right: 1%;

  /* padding: 0 50px 0 50px; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  gap: 250px;
}

.imgContainerText {
  padding-top: 0.5rem;
  font-weight: bold;
}

.infoImgContainer {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 15px;
}

.closeBtn {
  display: flex;
  justify-content: end;
}

.close-button:hover {
  color: #ffffff;
}

.close-button {
  cursor: pointer;
  font-size: 54px;
  color: #aaa;
  background: none;
  border: none;
}

/* VERSION WITHOUT BACKGROUND */

/* .imageContainer {
  display: inline-block;
  border-radius: 50%;
  padding: 15px;
  border: 2px solid white;
} */

.modalImg {
  width: 60px;
  height: 60px;
  border: 2px solid var(--white);
  border-radius: 50%;
}

.modal h2 {
  margin-bottom: 10px;
}

.modal button {
  cursor: pointer;
}

.modalCont{
  overflow: auto;
  padding-top: 150px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalDescription {
  margin-top: 40px;
  width: 80%;
  padding: 20px;
}

.closeContainer {
  display: flex;
  justify-content: end;
  height: 10px;
  position: relative;
  top: -14px;
  /* left: 10px; */
}

.modalH2 {
  padding-bottom: 1.5rem;
  padding-left: 20px;
}

/* info */

.info-button {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  border-radius: 1rem;
  margin-left: 0.5rem;
  display: none; /*temporarily disabled */
}

.info-button:hover {
  background-color: var(--magenta);
  cursor: pointer; /* Измените курсор по вашему желанию */
  /* Дополнительные стили для hover-эффекта */
}

.info-icon {
  width: 25px;
  color: var(--white);
}

.info-iconContainer {
  border-radius: 1rem;
  background-color: transparent;
  padding: 1px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  transition: background-color 0.7s ease;
}

.square:hover .info-iconContainer {
  /* background-color: var(--white);*/
  transition: background-color 0.2s ease;
}

.h3home{
  font-size: 1.7rem;
}


/* Auswahl section */

.auswahl-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 5rem 0 5rem;
}

.auswahl {
  min-height: 630px;
  /* flex-grow: 1; */
  position: relative;
  /* right: 14rem; */
  /* z-index: 99; */
  box-sizing: border-box;
  /* min-width: 700px;
  min-height: 630px; */
  overflow: auto;
  background: var(--blue);
  opacity: 100%;
  font-weight: initial;
  color: var(--white);
  padding: 4rem;
  border: solid;
  border-radius: 15px;
  /* margin-left: 10rem; */
  width: 50%;

  box-shadow: inset 0px -1px 16px 9px rgba(13, 199, 255, 1);
}

.auswahl-info {
  display: flex;
  justify-content: space-between;
}

#form_anfrage {
  /*   flex: 0 0 1000px;
 */
  /* margin-right: 100px; */
}

.fixed-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #4caf50; /* Зеленый цвет, вы можете изменить на свой выбор */
  color: var(--white);
  font-weight: bold;
  text-align: center;
  padding: 15px;
  z-index: 1000; /* При необходимости можете изменить значение z-index */
  display: none; /* Сначала скрываем сообщение */
}

.datePicker {
  display: flex;
  flex-direction: column;
  /* gap: 45px; */
  margin-top: 1.5rem;
  background: var(--lightpink);
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
}

.optional_label {
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--white);
  background-color: var(--magenta);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 0.875rem;
  right: 0.875rem;
}

.thirdDp {
  display: flex;
  padding: 0 0 1em;
}
.fourthDp {
  display: flex;
  padding: 0 0 1em;
}

/* Добавленные стили для галереи */
.gallery {
  display: flex;
}

.btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 8px;
  gap: 15px;
}

.btns.large {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
}

.btns li {
  cursor: pointer;
  transition: opacity 0.2s;
}

.btns li:hover {
  opacity: 0.8;
}

.hero {
  display: flex;
  justify-content: flex-end;
}

/* .stageImg {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
} */

.active {
  /* Стили для активного изображения в галерее */
  border: 2px solid #007bff; /* Пример границы для активного изображения */
}

/* Ключевая анимация fadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0.6;
  }
  90% {
    opacity: 1;
  }
}

.regFormContainer {
  display: flex;
  justify-content: center;
  background: var(--gradient);
}

.regForm {
  width: 550px;
  height: 550px;
  background-color: var(--blue);
  margin: 200px 0 521px;
  border-radius: 50px;
  padding: 84px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  border: 1px solid var(--white);
}

.regInput {
  margin: 1rem 0 1rem;
  padding: 10px 0 10px 15px;
  border-radius: 15px;
}

.regBtn {
  padding: 15px 41px;
  border-radius: 15px;
  border: none;
  font-size: 23px;
  background-color: var(--lightpink);
  color: var(--lightblue);
  margin-top: 3rem;
}

.regBtn:hover {
  background-color: var(--magenta);
  color: var(--white);
}

.regText {
  color: var(--white);
  font-size: 18px;
}

.inputDropdown {
  padding: 0 10px;
  text-align: left;
}

.informationContainer {
  margin: 100px 350px;
  background-color: var(--lightblue);
  height: 900px;
}

/* styles.css (или ваш файл стилей) */

.infoModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modalContent {
  position: relative;
  background: var(--white);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  height: 80%;
  max-width: 600px;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: -5px;
  right: 10px;
  font-size: 38px;
  cursor: pointer;
  font-weight: bold;
}

.close:hover {
  color: #666666;
}

.infoModalBtn {
  width: 50px;
  height: 25px;
  border: none;
  padding: 5px;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 5px;
}

.infoModalContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
}

.infoModalBtn:hover {
  background-color: var(--lightblue);
  color: #ffdff0;
}

:root {
  --glow-hue: 222deg;
  --shadow-hue: 180deg;
  --spring-easing: linear(
    0,
    0.002,
    0.01 0.9%,
    0.038 1.8%,
    0.156,
    0.312 5.8%,
    0.789 11.1%,
    1.015 14.2%,
    1.096,
    1.157,
    1.199,
    1.224 20.3%,
    1.231,
    1.231,
    1.226,
    1.214 24.6%,
    1.176 26.9%,
    1.057 32.6%,
    1.007 35.5%,
    0.984,
    0.968,
    0.956,
    0.949 42%,
    0.946 44.1%,
    0.95 46.5%,
    0.998 57.2%,
    1.007,
    1.011 63.3%,
    1.012 68.3%,
    0.998 84%,
    1
  );
  --spring-duration: 1.33s;
}
@property --shimmer {
  syntax: "<angle>";
  inherits: false;
  initial-value: 33deg;
}

@keyframes shimmer {
  0% {
    --shimmer: 0deg;
  }
  100% {
    --shimmer: 360deg;
  }
}

@keyframes shine {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes text {
  0% {
    background-position: 100% center;
  }
  100% {
    background-position: -100% center;
  }
}

/* .BTN {
  color: var(--bg);
  font-weight: 600;
  background-image: linear-gradient(
    315deg,
    
    #009fe3 -10%,
    #e6007e 50%,
    #fcd2e9 110%
  );
  padding: 0.8em 1.4em;
  position: relative;
  isolation: isolate;
  box-shadow: 0 2px 3px 1px hsl(var(--glow-hue) 50% 20% / 50%),
    inset 0 -10px 20px -10px hsla(var(--shadow-hue), 10%, 90%, 95%);
  border-radius: 0.66em;
  scale: 1;
  transition: all var(--spring-duration) var(--spring-easing);
  margin-bottom: 15px;
  margin-left: 16%;

}

.BTN:hover:not(:active),
.BTN.active {
  transition-duration: calc(var(--spring-duration) * 0.5);
  scale: 1.2;
  box-shadow: 0 4px 8px -2px hsl(var(--glow-hue) 50% 20% / 50%),
    inset 0 0 0 transparent;
}
.BTN:active {
  scale: 1.1;
  transition-duration: calc(var(--spring-duration) * 0.5);
}

.shimmer {
  position: absolute;
  inset: -40px;
  border-radius: inherit;
  mask-image: conic-gradient(
    from var(--shimmer, 0deg),
    transparent 0%,
    transparent 10%,
    black 36%,
    black 45%,
    transparent 50%,
    transparent 60%,
    black 85%,
    black 95%,
    transparent 100%
  );
  mask-size: cover;
  mix-blend-mode: plus-lighter;
  animation: shimmer 1s linear infinite both;
}
.BTN:hover .shimmer::before,
.BTN:hover .shimmer::after,
.BTN.active .shimmer::before,
.BTN.active .shimmer::after {
  opacity: 1;
  animation: shine 1.2s ease-in 1 forwards;
}
.shimmer::before,
.shimmer::after {
  transition: all 0.5s ease;
  opacity: 0;
  content: "";
  border-radius: inherit;
  position: absolute;
  mix-blend-mode: color;
  inset: 40px;
  pointer-events: none;
}
.shimmer::before {
  box-shadow: 0 0 1px 1px hsl(var(--glow-hue) 20% 95%),
    0 0 1px 1px hsl(var(--glow-hue) 20% 80%),
    0 0 1px 1px hsl(var(--glow-hue) 50% 70%),
    0 0 1px 1px hsl(var(--glow-hue) 100% 70%);
  z-index: -1;
}

.shimmer::after {
  box-shadow: inset 0 0 0 1px hsl(var(--glow-hue) 70% 95%),
    inset 0 0 2px 1px hsl(var(--glow-hue) 100% 80%),
    inset 0 0 5px 2px hsl(var(--glow-hue) 100% 70%);
  z-index: 2;
}

.BTN .text {
  color: black;
  background-clip: text;
  background-color: var(--bg);
  background-image: linear-gradient(
    120deg,
    transparent,
    hsla(var(--glow-hue), 100%, 80%, 0.66) 40%,
    hsla(var(--glow-hue), 100%, 90%, 0.9) 50%,
    transparent 52%
  );
  background-repeat: no-repeat;
  background-size: 300% 300%;
  background-position: center 200%;
}

.BTN:hover .text,
.BTN.active .text {
  animation: text 0.66s ease-in 1 both;
} */

/* @MEDIA */

@media screen and (max-width: 1740px) and (min-width: 1642px) {
  .modalContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .imgContainer {
    padding: 10px 0 5px 0;
  }

  .stageImg {
    width: 918px;
  }

  .carousel-arrows {
    width: 918px;
  }

  .modalHeader {
    margin: 1rem 0 2.5rem 0;
  }

  .modalDesc {
    margin: 2.5rem 0 0 0;
  }

  #dropdown-1 {
    width: 30%;
  }

  #dropdown-2 {
    width: 40%;
  }
  #dropdown-3 {
    width: 50%;
  }
}

@media screen and (max-width: 1642px) and (min-width: 1043px) {
  .formTextHolder {
    font-size: 20px;
    margin: 5px;
  }
  .modalContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .imgContainer {
    padding: 10px 0 5px 0;
  }

  .infoContainer {
    padding-left: 100px;
  }

  .modal {
    /* width: 85%; */
    overflow-y: auto;
  }


  

  .stageImg {
    width: 918px;
    height: 600px;
    border-radius: 15px;
  }

  .carousel-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 918px;
    display: flex;
    justify-content: space-between;
    z-index: 99;
  }
  .modalHeader {
    flex-direction: column;
    gap: 2.5rem;
  }
  #dropdown-0 {
    left: 0;
  }
  #dropdown-1 {
    width: 30%;
  }
  #dropdown-2 {
    width: 40%;
  }
  #dropdown-3 {
    width: 70%;
  }
}

@media screen and (max-width: 1043px) and (min-width: 768px) {
  h1 {
    font-size: 2em;
    font-weight: 400;
    margin: 0;
  }

  h2 {
    font-weight: 300;
    font-size: 1.3em;
    margin: 0;
  }

  h3 {
    font-weight: 300;
    margin: 0;
    font-size: 18px;
  }

  .stage_size_wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0;
    padding-right: 0;
    gap: 20px;
  }

  .booking_dropdown_text {
    font-size: 1.2em;
    margin: auto auto auto 10px;
  }

  .square {
    width: 100%;
    padding: 0;
  }

  .square img {
    width: 10%;
  }

  .section_1_blue {
    padding-top: 1rem;
    padding-bottom: 3rem;
    display: flex;
  }

  .section_stage_sizes {
    padding-top: 3rem;
    position: relative;
  }

  .band {
    width: 82%;
    right: -15%;
    bottom: 14px;
    display: none;
  }

  .band_floor {
    height: 5%;
  }

  .floating_section_booking {
    /* flex-grow: 1; */
    position: relative;
    align-items: center;
    z-index: 99;
    color: var(--white);
    /* background-color: var(--magenta); */
    display: flex;
    flex-direction: row;
    flex-direction: column;
    /* min-width: 100%; */
  }

  .booking_dropdown {
    width: 98%;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .booking_dropdown > button.pink {
    width: 100%;
    height: 4.5em;
  }

  .hidden_dropdown {
    position: static;
    left: 0;
    border: none;
    border-top: 1px solid var(--magenta);
    min-width: 100%;
    box-sizing: border-box;
    padding: 10%;
  }
  .hidden_dropdownDp {
    position: static;
    left: 0;
    border: none;
    border-top: 1px solid var(--magenta);
    min-width: 100%;
    box-sizing: border-box;
    padding: 10%;
  }

  .hidden_dropdown::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid var(--white);
    position: absolute;
    top: -0.5rem;
    left: 2rem;
    z-index: 10;
  }

  .hidden_dropdown .anfragebtn {
    font-size: 1.2em;
  }
  .hidden_dropdownDp .anfragebtn {
    font-size: 1.2em;
  }

  .hidden_dropdown h3 {
    font-size: 1.2em;
  }
  .hidden_dropdownDp h3 {
    font-size: 1.2em;
  }

  .infoContainer {
    gap: 20px;
    flex-direction: row;
    padding: 0;
  }

  .auswahl {
    display: none;
  }

  #form_anfrage {
    /* flex: 0; */
    margin: 0;
    position: static;
    /* padding: 7px; */
  }

  footer {
    width: 100%;
    box-sizing: border-box;
  }

  .firstButton {
    /* border-radius: 20px 20px 0 0; */
  }

  .lastButton {
    /* border-radius: 0 0 20px 20px; */
  }

  .button.pink[disabled] {
    border-radius: 0 0 20px 20px;
  }

  .booking_dropdown > button.pink {
    /* border-radius: 0 0 18px 18px; */
  }

  .formTextHolder {
    font-size: 20px;
  }

  .anfragen {
    flex-direction: column;
  }

  .anfrageLeft {
    width: 100%;
  }

  .anfrageRight {
    width: 100%;
  }

  .logo {
    width: 50%;
  }

  .content_margin_sides {
    margin-left: 10%;
    margin-right: 10%;
  }

  .imgContainer {
    flex-direction: column;
    padding: 2rem 0;
  }

  .modalImg {
    width: 60px;
  }

  .modal {
    width: 85%;
    overflow-y: auto;
  }

  .modalStageName {
    padding: 0;
    font-size: 25px;
  }

  .stageImg {
    width: 95%;
    /* height: auto; */
    height: 490px;
  }

  .close-button {
    font-size: 35px;
  }

  .closeContainer {
    left: 17px;
  }

  .modalHeader {
    flex-direction: column;
    gap: 20px;
  }

  .infoImgContainer {
    flex-direction: column;
  }

  .modalContainer {
    flex-direction: column;
  }

  .gallery {
    /* display: block; */
  }

  .arrow-left {
    font-size: 25px;
  }

  .carousel-arrows {
    display: none;
    top: 47%;
  }

  .galleryMini {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
  }

  .hero {
    display: block;
    margin: auto;
  }

  .gallerySlider {
    width: 380px;
    height: 100px;
  }

  .galleryCont {
    flex-direction: column;
    align-items: center;
  }

  .slick-slider {
    padding: 20px 30px;
  }

  .slick-prev {
    left: 0px;
    top: 50px;
    transform: translateY(-50%);
  }

  .slick-next {
    left: 356px;
    top: 50px;
    transform: translateY(-50%);
  }

  .galleryDefault {
    width: 100%;
  }

  .gallery .hero {
    display: flex;
    justify-content: center;
  }

  .modalDesc {
    padding: 0;
    font-size: 15px;
  }

  .gallery .hero.zoomed img.stageImg {
    width: auto;
    height: 13%;
    max-height: 80vh;
  }

  .react-datepicker__input-container input {
    font-size: 12px;
  }

  .booking_dropdown .image {
    width: 7%;
    height: 79%;
    margin: 20px;
  }

  .infoContainer {
    gap: 100px;
  }

  .gridMain {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2em;
    font-weight: 400;
    margin: 0;
  }

  h2 {
    font-weight: 300;
    font-size: 1.3em;
    margin: 0;
  }

  .header-buffer {
    display: none;
  }

  nav > ul {
    display: none;
  }

  .hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 20px;
    justify-content: space-between;
    width: 25px;
  }

  .hamburger .bar {
    background-color: #009fe3;
    border-radius: 10px;
    height: 3px;
    transition: all .4s ease-in-out;
    width: 100%;
  }

  .hamburger.open .bar:first-child {
    background-color: #e6007e;
    -webkit-transform: rotate(45deg) translate(9px, 7px);
    transform: rotate(45deg) translate(9px, 7px);
  }

  .hamburger.open .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .bar:nth-child(3) {
    background-color: #e6007e;
    -webkit-transform: rotate(-45deg) translate(5px, -3px);
    transform: rotate(-45deg) translate(5px, -3px);
  } 

  .gradient_bg {
    position: relative;
    top: 0;
    z-index: 50;
  }

  .stage_size_wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0;
    padding-right: 0;
    gap: 20px;
  }

  .booking_dropdown_text {
    font-size: 1.2em;
    margin: auto auto auto 10px;
  }

  .square {
    width: 80%;
    padding: 0;
    margin: auto;
  }

  .square img {
    width: 10%;
  }

  .square img.square-img {
    width: 30%;
  }

  .section_1_blue {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
  }

  .section_stage_sizes {
    padding-top: 3rem;
    position: relative;
  }

  .band {
    width: 82%;
    right: -15%;
    bottom: 14px;
    display: none;
  }

  .band_floor {
    height: 5%;
  }

  .floating_section_booking {
    /* flex-grow: 1; */
    position: relative;
    align-items: normal;
    z-index: 99;
    color: var(--white);
    border-radius: 1.5rem;
    /* background-color: var(--magenta); */
    display: flex;
    flex-direction: row;
    flex-direction: column;
    width: calc(100% - 3rem);
  }

  .booking_dropdown {
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 auto;
    border-right: 0;
    border-bottom: 1px solid var(--magenta)
  }

  .booking_dropdown > button {
    height: 3.5rem;
  }

  .booking_dropdown:first-child > button {
    border-radius: 1rem 1rem 0 0;
  }

  .lastButton > button {
    border-radius: 0 0 1rem 1rem;
  }

  .booking_dropdown > button.pink {
    width: 100%;
    height: 4.5em;
  }

  .hidden_dropdown {
    position: static;
    left: 0;
    border: none;
    border-top: 1px solid var(--magenta);
    border-radius: 0;
    min-width: 100%;
    margin-left: 0;
    box-sizing: border-box;
    padding: 1rem;
  }

  #dropdown-3 {
    border-radius: 0 0 0.75rem 0.75rem;
  }

  .hidden_dropdown::before {
    display: none;
  }

  .hidden_dropdownDp {
    position: static;
    left: 0;
    border: none;
    border-top: 1px solid var(--magenta);
    min-width: 100%;
    box-sizing: border-box;
    padding: 10%;
  }

  .hidden_dropdown .anfragebtn {
    font-size: 1.2em;
  }

  .hidden_dropdown h3 {
    font-size: 1.2em;
  }

  .hidden_dropdownDp .anfragebtn {
    font-size: 1.2em;
  }

  .hidden_dropdownDp h3 {
    font-size: 1.2em;
  }

  .infoContainer {
    gap: 20px;
    flex-direction: row;
    padding: 0;
    flex-wrap: wrap;

  }

  .infoAuswahl {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  .infoOrt {
    padding: 0.5rem 0.25rem;
  }

  .infoOrt > img {
    margin: 0 0.75rem 0 0;
  }

  .infoZeit {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 3rem auto;
    grid-template-areas: 
      'icon aufbau'
      '. start'
      '. ende'
      '. abbau';
  }
 
  .infoZeit > img {
    grid-area: icon;
  }
  .infoEventStart{
    grid-area: start;
  }
  .infoEventEnde{
    grid-area: ende;
  }
  .infoAufbau{
    grid-area: aufbau;
  }
  .infoAbbau {
    grid-area: abbau;
  }

  .infoEventStart, .infoEventEnde, .infoAufbau, .infoAbbau {
    text-align: left;
    margin: 0 0;
  }

  .infoAufbau::after {
    background: repeating-linear-gradient(180deg, var(--lightblue), var(--lightblue) 4px, transparent 4px, transparent 8px);
    width: 2px;
    height: 50%;
    top: calc(100% - 0.25rem);
    left: calc(-1.625rem + 1px);
    right: auto;
  }

  .infoEventStart::before,
  .infoEventEnde::before {
    content: '';
    position: absolute;
    background-color: var(--blue);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    top: calc(50% - 0.125rem);
    left: -1.75rem;
    z-index: 10;
  }

  .infoEventStart::after {
    width: 2px;
    height: 100%;
    top: 50%;
    left: calc(-1.625rem + 1px);
    right: auto;
  }

  .infoAbbau::before {
    background: repeating-linear-gradient(180deg, var(--lightblue), var(--lightblue) 4px, transparent 4px, transparent 8px);
    width: 2px;
    height: 100%;
    top: calc(-50% + 1px);
    left: calc(-1.625rem + 1px);
    right: auto;
  }

  .infoAbbau::after {
    content: '';
    position: absolute;
    background-color: var(--lightblue);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    top: calc(50% - 1px);
    left: -1.75rem;
  }

  .auswahl {
    display: none;
  }

  #form_anfrage {
    /* flex: 0; */
    margin: 0;
    position: static;
    padding: 1rem;
  }

  footer {
    width: 100%;
    box-sizing: border-box;
  }

  .firstButton {
    /* border-radius: 20px 20px 0 0; */
  }

  .lastButton {
    padding-left: 0;
  }

  .button.pink[disabled] {
    border-radius: 0 0 20px 20px;
  }

  .booking_dropdown > button.pink {
    /* border-radius: 0 0 18px 18px; */
  }

  .formTextHolder {
    font-size: 20px;
  }

  .anfragen {
    flex-direction: column;
    margin-top: 2rem;
  }

  .anfrageLeft {
    width: 100%;
  }

  .anfrageRight {
    width: 100%;
  }

  .logo {
    width: 50%;
  }

  .logo a,
  .logo a img {
    display: block;
  }

  #kontakt, 
  #impressum {
    padding-block: 4rem;
  }

  .content_margin_sides {
    text-align: center;
    padding: 0 1.5rem;
  }

  .imgContainer {
    flex-direction: column;
    padding: 0;
  }

  .modalImg {
    width: 60px;
  }

  .modal {
    width: 85%;
    overflow-y: auto;
  }

  .modalStageName {
    padding: 0;
    font-size: 25px;
  }

  .stageImg {
    width: 95%;
    /* height: auto; */
    height: 230px;
  }

  .close-button {
    font-size: 35px;
  }

  .closeContainer {
    left: 17px;
  }

  .modalHeader {
    flex-direction: column;
    gap: 20px;
  }

  .infoImgContainer {
    flex-direction: column;
  }

  .modalContainer {
    flex-direction: column;
    padding-top: 15px;
  }

  .gallery {
    /* display: block; */
  }

  .arrow-left {
    font-size: 25px;
  }

  .carousel-arrows {
    display: none;
    top: 47%;
  }

  .galleryMini {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 10px;
  }

  .hero {
    display: block;
    margin: auto;
  }

  .gallerySlider {
    width: 301px;
    height: 100px;
    /* height: 300px;
    margin: 0 auto; */
  }

  .galleryCont {
    flex-direction: column;
    align-items: center;
  }

  .slick-slider {
    padding: 20px 30px;
  }

  .slick-prev {
    left: 0px;
    top: 45px;
    transform: translateY(-50%);
  }

  .slick-next {
    left: 280px;
    top: 45px;
    transform: translateY(-50%);
  }

  .galleryDefault {
    width: 100%;
  }

  .gallery .hero {
    display: flex;
    justify-content: center;
  }

  .modalDesc {
    padding: 0;
    font-size: 15px;
  }

  .gallery .hero.zoomed img.stageImg {
    width: auto;
    height: 24%;
    max-height: 80vh;
  }

  .react-datepicker__input-container input {
    font-size: 12px;
  }

  .booking_dropdown .image {
    width: 12%;
    height: 86%;
  }

  #kontakt a {
    font-size: 1.25rem;
    margin-left: 0;
  }

  footer {
    text-align: center;
    z-index: 98;
  }
  .gridMain {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .stages {
    padding: 2.5rem 0;
  }

  

  
.anfrageAuswahl{
  flex-direction: column;
  gap: 30px;
  margin: -1rem;
}

.textArea{
  width: 100%;
}

.auswahlTabelle{
  width: 100%;
  padding: 0;
}

.flexCol{
  padding: 0;
}

.slick-track {
  height: 50px !important;
}
}
