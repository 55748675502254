/* GridAnimation.css */
#grid-container {
    display: grid;
    gap: 2px;
    perspective: 800px;
    transform-style: preserve-3d;


    /* border-right: 2px solid dodgerblue;
    border-bottom: 2px solid dodgerblue;
    padding-right: 10px;
    padding-bottom: 10px; */
    
  }
  
  .grid-cell {
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    border-radius: 5px;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.8s, border-radius 0.8s;
    transform: rotateY(180deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .active {
    background-color: #77d6ff;
    box-shadow: 0 0 5px #9fc4fe ;
    transform: rotateY(0deg);
    border-radius: 20%;
  }
  
  #numbers-container {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 5px;
    font-size: 30px;
    gap: 10px;
    width: 83%;

  }
  
  .number,
  .symbol {
    opacity: 0;
    transition: opacity 0.5s;
  }
  

  /* #numbers-container{

  display: flex;
  justify-content: center;
  padding-right: 30px;
  font-size: 35px;
  font-weight: 500;
} */

.number{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(170, 217, 255);
    font-size: 17px;
    font-weight: 500; 
}

.gridCont{

display: flex;
align-items: center;
gap: 8px;
}

.grid{
    padding-top: 15px;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    
}

.gridMain{
  position: static;
  width: 183px;
  height: 163px;
  display: flex;
  align-items: center;
  /* padding-left: 2rem; */
}